// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/AppMenu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/AppMenu.tsx");
  import.meta.hot.lastModified = "1726719770000";
}
// REMIX HMR END

import { SYSTEM_USER_TYPES } from '~/lib/constants';
import { AdminMenu } from './AdminMenu';
import { CustomerMenu } from './CustomerMenu';
export const AppMenu = ({
  isLoggedIn = false,
  user = null
}) => {
  const userType = user?.type || null;
  return <div className='text-sm text-stone-500 dark:text-stone-400'>
      {userType === SYSTEM_USER_TYPES.BE_USER ? <AdminMenu user={user} /> : <CustomerMenu user={user} isLoggedIn={isLoggedIn} />}
    </div>;
};
_c = AppMenu;
var _c;
$RefreshReg$(_c, "AppMenu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;