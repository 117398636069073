// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/CustomerMenu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/CustomerMenu.tsx");
  import.meta.hot.lastModified = "1726719770000";
}
// REMIX HMR END

import { useParams } from '@remix-run/react';
import { APP_PAGES_URL } from '~/lib/constants';
import { DASHBOARD } from '~/lib/lang/en';
import { useWorkspace } from '~/components/Workspaces/WorkspaceContext';
import { MenuList } from './MenuList';
import { PUBLIC_HOME, LOGIN_MENU, PUBLIC_CUSTOMER_APP_MENU, PROTECTED_CUSTOMER_APP_MENU } from './data/menu';
export const CustomerMenu = ({
  user = null,
  isLoggedIn = false
}) => {
  _s();
  const {
    workspaceId = '',
    entityId = undefined
  } = useParams();
  const {
    workspace,
    entitiesList
  } = useWorkspace();
  let menuItems = PUBLIC_HOME.concat(PUBLIC_CUSTOMER_APP_MENU).concat(LOGIN_MENU);
  if (isLoggedIn && user?.id) {
    const mySelectedEntityId = entitiesList.find(item => `${item?.entityId}`.toLowerCase() === `${entityId}`.toLowerCase())?.entityId || entitiesList?.[0]?.entityId;
    const workspaceMenu = workspaceId && workspace?.id ? PROTECTED_CUSTOMER_APP_MENU({
      workspaceId,
      entityId: mySelectedEntityId
    }) : [{
      name: DASHBOARD,
      url: APP_PAGES_URL.APP,
      permissionsRequired: [],
      children: [],
      end: true
    }];
    menuItems = workspaceMenu.concat(PUBLIC_CUSTOMER_APP_MENU);
  }
  return <MenuList menu={menuItems} />;
};
_s(CustomerMenu, "SRcCwDB5+ZW4eGu+Bv+3xy/pQW0=", false, function () {
  return [useParams, useWorkspace];
});
_c = CustomerMenu;
var _c;
$RefreshReg$(_c, "CustomerMenu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;