// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/MenuList/MenuList.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/MenuList/MenuList.tsx");
  import.meta.hot.lastModified = "1726719770000";
}
// REMIX HMR END

import { NavLink } from '@remix-run/react';
import { cn } from '~/lib/utils';
import { ChildMenu } from './ChildMenu';
import { RestrictedElement } from '~/components/RestrictedElement';
export const MenuList = ({
  menu = []
}) => {
  return <ul className='block py-3'>
      {menu?.map((item, idx) => {
      return <RestrictedElement permissions={item?.permissionsRequired} key={`${idx}-${item.name}`}>
            <li className='relative py-0.5 border-primary-50 border-b first-of-type:border-t'>
              {item?.children?.length > 0 ? <ChildMenu menu={item} key={`${idx}-${item.name}`} /> : <NavLink reloadDocument to={item.url} className={({
            isActive
          }) => cn('text-sm hover:text-primary-800 duration-150 transition-all hover:bg-primary-50 px-4 rounded-md md:py-3 py-4 block border-b md:border-none text-left', isActive ? 'bg-primary-50' : '')} end={item?.end}>
                  {item.name}
                </NavLink>}
            </li>
          </RestrictedElement>;
    })}
    </ul>;
};
_c = MenuList;
var _c;
$RefreshReg$(_c, "MenuList");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;